define('ember-feature-flags/tests/helpers/reset-feature-flags', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  exports.resetFeatureFlags = resetFeatureFlags;

  function resetFeatureFlags(app) {
    _ember['default'].deprecate('[ember-feature-flags] resetFeatureFlags is no longer needed before each test execution, and will be removed');
    var featuresService = app.__container__.lookup('features:-main');
    featuresService.setup({});
  }

  _ember['default'].Test.registerHelper('resetFeatureFlags', resetFeatureFlags);
});