define('ivy-videojs/components/ivy-videojs-player', ['exports', 'ember', 'videojs'], function (exports, _ember, _videojs) {
  'use strict';

  exports['default'] = _ember['default'].Component.extend({
    tagName: 'video',

    classNames: ['video-js'],

    mergedProperties: ['playerEvents'],

    playerEvents: {
      abort: 'abort',
      canplay: 'canplay',
      canplaythrough: 'canplaythrough',
      durationchange: 'durationchange',
      emptied: 'emptied',
      ended: 'ended',
      error: 'error',
      loadeddata: 'loadeddata',
      loadedmetadata: 'loadedmetadata',
      loadstart: 'loadstart',
      pause: 'pause',
      play: 'play',
      playing: 'playing',
      progress: 'progress',
      ratechange: 'ratechange',
      resize: 'resize',
      seeked: 'seeked',
      seeking: 'seeking',
      stalled: 'stalled',
      suspend: 'suspend',
      timeupdate: 'timeupdate',
      useractive: 'useractive',
      userinactive: 'userinactive',
      volumechange: 'volumechange',
      waiting: 'waiting'
    },

    bindPropertyToPlayer: function bindPropertyToPlayer(player, property) {
      var playerProperty = arguments.length <= 2 || arguments[2] === undefined ? property : arguments[2];
      return (function () {
        var observer = function observer() {
          this.setPlayerProperty(player, playerProperty, this.get(property));
        };

        var scheduledObserver = function scheduledObserver() {
          _ember['default'].run.scheduleOnce('render', this, observer);
        };

        this._addPlayerObserver(property, this, scheduledObserver);

        // Invoke the observer once to set the initial value on the player.
        observer.call(this);
      }).apply(this, arguments);
    },

    didInsertElement: function didInsertElement() {
      var _this = this;

      var player = (0, _videojs['default'])(this.get('element'), this.get('setup'));

      player.ready(function () {
        _this.one('willDestroyElement', function () {
          player.dispose();
        });

        var playerEvents = _this.get('playerEvents');
        if (playerEvents) {
          for (var key in playerEvents) {
            _this.sendActionOnPlayerEvent(player, key, playerEvents[key]);
          }
        }

        _this.sendAction('ready', player, _this);
      });
    },

    sendActionOnPlayerEvent: function sendActionOnPlayerEvent(player, action) {
      var playerEvent = arguments.length <= 2 || arguments[2] === undefined ? action : arguments[2];
      return (function () {
        var _this2 = this;

        var listenerFunction = function listenerFunction() {
          for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
            args[_key] = arguments[_key];
          }

          _this2.sendAction.apply(_this2, [action, player, _this2].concat(args));
        };

        this._onPlayerEvent(player, playerEvent, listenerFunction);
      }).apply(this, arguments);
    },

    setPlayerProperty: function setPlayerProperty(player, playerProperty, value) {
      var propertyMethod = player[playerProperty];
      if (propertyMethod) {
        var previousValue = propertyMethod.call(player);
        if (previousValue !== value) {
          propertyMethod.call(player, value);
        }
      }
    },

    _addPlayerObserver: function _addPlayerObserver(property, target, observer) {
      this.addObserver(property, target, observer);

      this.one('willDestroyElement', this, function () {
        this.removeObserver(property, target, observer);
      });
    },

    _onPlayerEvent: function _onPlayerEvent(player, eventName, listenerFunction) {
      player.on(eventName, listenerFunction);
    }
  });
});