define("ember-cli-simple-validation/components/validation-error-field", ["exports", "ember"], function (exports, _ember) {
    "use strict";

    var ValidationErrorField = _ember["default"].Component.extend({
        tagName: "span",
        classNameBindings: ["visible"],
        attributeBindings: ["array", "className", "model", "field", "validation", "submitted", "delayed", "index"],
        initialize: _ember["default"].on("init", function () {
            var field = this.get("field");
            var computedKeys = ["validation", "isPrimed", "submitted"];

            if (this.get("array")) {
                var fields = field.split(",");
                fields.forEach(function (f) {
                    computedKeys.push("array.@each." + f);
                });
                _ember["default"].defineProperty(this, "isPrimed", _ember["default"].computed("model." + fields[0] + "IsPrimed", function () {
                    return this.get("model." + fields[0] + "IsPrimed");
                }));
            } else {
                computedKeys.push("fieldName");
                _ember["default"].defineProperty(this, "isPrimed", _ember["default"].computed("model." + field + "IsPrimed", function () {
                    return this.get("model." + field + "IsPrimed");
                }));
                _ember["default"].defineProperty(this, "fieldName", _ember["default"].computed("model." + field, function () {
                    return this.get("model." + field);
                }));
            }

            _ember["default"].defineProperty(this, "visible", _ember["default"].computed(computedKeys.join(","), function () {
                var index = this.get("index");
                var isPrimed = this.get("isPrimed");
                var delayed = this.get("delayed");
                var submitted = this.get("submitted");
                var className = this.get("className");
                var validator = this.get("validation");
                var targetObject = this.get("targetObject") || this.get("_targetObject");
                var fieldValidation = targetObject.get(validator + index + "Validation");
                var validation = index >= 0 ? fieldValidation : validator;
                var condition = delayed === true ? !validation && submitted : !validation && (isPrimed || submitted);
                if (condition) {
                    return className;
                }
                return className ? "hidden " + className : "hidden";
            }));
        })
    });

    exports["default"] = ValidationErrorField;
});