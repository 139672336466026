define('ember-cli-mirage/shorthands/delete', ['exports', 'ember-cli-mirage/utils/inflector', 'ember-cli-mirage/shorthands/utils'], function (exports, _emberCliMirageUtilsInflector, _emberCliMirageShorthandsUtils) {
  'use strict';

  /*
    Shorthands for DELETE requests.
  */
  exports['default'] = {

    /*
      Remove the model from the db of type *type*.
       This would remove the user with id :id:
        Ex: this.stub('delete', '/contacts/:id', 'user');
    */
    string: function string(type, db, request) {
      var id = _emberCliMirageShorthandsUtils['default'].getIdForRequest(request);
      var collection = (0, _emberCliMirageUtilsInflector.pluralize)(type);

      if (!db[collection]) {
        console.error("Mirage: The route handler for " + request.url + " is trying to remove data from the " + collection + " collection, but that collection doesn't exist. To create it, create an empty fixture file or factory.");
      }

      db[collection].remove(id);

      return undefined;
    },

    /*
      Remove the model and child related models from the db.
       This would remove the contact with id `:id`, and well
      as this contact's addresses and phone numbers.
        Ex: this.stub('delete', '/contacts/:id', ['contact', 'addresses', 'numbers');
    */
    array: function array(_array, db, request) {
      var id = _emberCliMirageShorthandsUtils['default'].getIdForRequest(request);
      var parentType = _array[0];
      var parentCollection = (0, _emberCliMirageUtilsInflector.pluralize)(parentType);
      var types = _array.slice(1);

      if (!db[parentCollection]) {
        console.error("Mirage: The route handler for " + request.url + " is trying to remove data from the " + parentCollection + " collection, but that collection doesn't exist. To create it, create an empty fixture file or factory.");
      }

      db[parentCollection].remove(id);

      var query = {};
      var parentIdKey = parentType + '_id';
      query[parentIdKey] = id;

      types.forEach(function (type) {
        var collection = (0, _emberCliMirageUtilsInflector.pluralize)(type);

        if (!db[parentCollection]) {
          console.error("Mirage: The route handler for " + request.url + " is trying to remove data from the " + collection + " collection, but that collection doesn't exist. To create it, create an empty fixture file or factory.");
        }

        db[collection].remove(query);
      });

      return undefined;
    },

    /*
      Remove the model from the db based on singular version
      of the last portion of the url.
       This would remove contact with id :id:
        Ex: this.stub('delete', '/contacts/:id');
    */
    undefined: (function (_undefined) {
      function undefined(_x, _x2, _x3) {
        return _undefined.apply(this, arguments);
      }

      undefined.toString = function () {
        return _undefined.toString();
      };

      return undefined;
    })(function (undef, db, request) {
      var id = _emberCliMirageShorthandsUtils['default'].getIdForRequest(request);
      var url = _emberCliMirageShorthandsUtils['default'].getUrlForRequest(request);
      var urlNoId = id ? url.substr(0, url.lastIndexOf('/')) : url;
      var type = (0, _emberCliMirageUtilsInflector.singularize)(urlNoId.substr(urlNoId.lastIndexOf('/') + 1));
      var collection = (0, _emberCliMirageUtilsInflector.pluralize)(type);

      db[collection].remove(id);

      return undefined;
    })
  };
});