define('ember-data-route/index', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  var get = _ember['default'].get;
  var Mixin = _ember['default'].Mixin;

  exports['default'] = Mixin.create({
    resetController: function resetController() {
      var model = get(this, 'controller.model');

      if (!get(model, 'isDeleted')) {
        model.rollbackAttributes();
      }
    },
    actions: {
      willTransition: function willTransition(transition) {
        var model = get(this, 'controller.model');

        if (get(model, 'hasDirtyAttributes') && !this.willTransitionConfirm(transition)) {
          transition.abort();
        }
      }
    },
    willTransitionConfirm: function willTransitionConfirm() {
      return true;
    }
  });
});