// Should be dropped and usage fixed once http://git.io/vB5wQ is resolved.

define('ember-controller/proxy', ['ember'], function(Ember) {
  'use strict';

  return {
    'default': Ember['default'].ArrayProxy,
    'ObjectProxy': Ember['default'].ObjectProxy
  };
});
