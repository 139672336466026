define("ember-cli-flash/utils/object-compact", ["exports"], function (exports) {
  "use strict";

  exports["default"] = objectCompact;

  function objectCompact(objectInstance) {
    var compactedObject = {};

    for (var key in objectInstance) {
      var value = objectInstance[key];

      if (Ember.isPresent(value)) {
        compactedObject[key] = value;
      }
    }

    return compactedObject;
  }
});