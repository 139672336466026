define('ember-feature-flags/tests/helpers/with-feature', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  exports.withFeature = withFeature;

  function withFeature(app, featureName) {
    var featuresService = app.__container__.lookup('features:-main');
    featuresService.enable(featureName);
  }

  _ember['default'].Test.registerHelper('withFeature', withFeature);
});