define('ember-plupload/system/sum-by', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  var get = _ember['default'].get;

  exports['default'] = function (target, key) {
    return _ember['default'].A(target).reduce(function (E, obj) {
      return E + get(obj, key);
    }, 0);
  };
});