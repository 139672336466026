define('ember-cli-clipboard/components/copy-button', ['exports', 'ember', 'ember-cli-clipboard/templates/components/copy-button'], function (exports, _ember, _emberCliClipboardTemplatesComponentsCopyButton) {
  'use strict';

  /* global Clipboard */

  var get = _ember['default'].get;
  var set = _ember['default'].set;

  exports['default'] = _ember['default'].Component.extend({
    layout: _emberCliClipboardTemplatesComponentsCopyButton['default'],
    tagName: 'button',
    classNames: ['copy-btn'],
    attributeBindings: ['clipboardText:data-clipboard-text', 'clipboardTarget:data-clipboard-target', 'clipboardAction:data-clipboard-action', 'buttonType:type'],

    /**
     * @property {Array} clipboardEvents - events supported by clipboard.js
     */
    clipboardEvents: ['success', 'error'],

    /**
     * @property {String} buttonType - type attribute for button element
     */
    buttonType: 'button',

    didInsertElement: function didInsertElement() {
      var _this = this;

      var clipboard = new Clipboard('#' + this.get('elementId'));
      set(this, 'clipboard', clipboard);

      get(this, 'clipboardEvents').forEach(function (action) {
        clipboard.on(action, _ember['default'].run.bind(_this, function (e) {
          try {
            this.sendAction(action, e);
          } catch (error) {
            _ember['default'].Logger.debug(error.message);
          }
        }));
      });
    },

    willDestroyElement: function willDestroyElement() {
      get(this, 'clipboard').destroy();
    }
  });
});