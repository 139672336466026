define("ember-cli-simple-validation/mixins/validate", ["exports", "ember"], function (exports, _ember) {
    "use strict";

    function factory(mixin) {
        return mixin.get("constructor.ClassMixin.ownerConstructor");
    }

    function eachAttrs(mixin) {
        var attributes = [];
        factory(mixin).eachComputedProperty(function (field, meta) {
            if (meta.validateEach) {
                attributes.push({ field: field, options: meta.options, fieldName: meta.fieldName });
            }
        });
        return attributes;
    }

    function attrs(mixin) {
        var attributes = [];
        factory(mixin).eachComputedProperty(function (field, meta) {
            if (field.indexOf("Validation") > 0) {
                attributes.push(field);
            }
            if (meta.validateEach) {
                mixin.get("model").forEach(function (model, index) {
                    attributes.push(field + index + "Validation");
                });
            }
        });
        return attributes;
    }

    function run(value, options, context) {
        context.notifyPropertyChange("valid");
        if (options instanceof RegExp) {
            return options.test(value);
        }
        if (options instanceof Function) {
            return options.apply(context, arguments);
        }
        if (typeof value === "boolean") {
            return value;
        }
        if (typeof value === "string") {
            return value.trim().length > 0;
        }
        return value;
    }

    var ValidationMixin = _ember["default"].Mixin.create({
        valid: _ember["default"].computed(function () {
            var self = this;
            var result = true;
            attrs(this).forEach(function (attr) {
                result = self.get(attr) && result;
            });
            return result;
        }),
        each: _ember["default"].observer("model.@each.isDone", function () {
            var self = this;
            this.notifyPropertyChange("valid");
            eachAttrs(this).forEach(function (attr) {
                self.get("model").forEach(function (obj, index) {
                    var cpStringForExpansion = "model.@each.{" + attr.fieldName.join(",") + "}";
                    _ember["default"].defineProperty(self, attr.field + index + "Validation", _ember["default"].computed(cpStringForExpansion, function () {
                        if (self.get("model").objectAt(index)) {
                            var value = self.get("model").objectAt(index).getWithDefault(attr.fieldName[0], "");
                            return run(value, attr.options, self, index);
                        }
                    }));
                });
            });
        })
    });

    var validate = function validate() {
        for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
            args[_key] = arguments[_key];
        }

        var options;

        if (typeof args[args.length - 1] !== 'string') {
            options = args.pop();
        }

        var computedProperty = _ember["default"].computed(function () {
            var value = this.getWithDefault(args[0], '');
            return run(value, options, this);
        });

        return computedProperty.property.apply(computedProperty, args);
    };

    var validateEach = function validateEach() {
        for (var _len2 = arguments.length, args = Array(_len2), _key2 = 0; _key2 < _len2; _key2++) {
            args[_key2] = arguments[_key2];
        }

        var options;

        if (typeof args[args.length - 1] !== 'string') {
            options = args.pop();
        }

        var computedProperty = _ember["default"].computed(function () {
            return true;
        }).meta({ validateEach: true, options: options, fieldName: args });

        return computedProperty.property.apply(computedProperty, args);
    };

    exports.ValidationMixin = ValidationMixin;
    exports.validate = validate;
    exports.validateEach = validateEach;
});