/* global Intercom */

define('intercom', [], function() {
  'use strict';

  return {
    'default': function() {
      return window.Intercom;
    }
  };
});
