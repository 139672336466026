define('ember-plupload/computed/w', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  var w = _ember['default'].String.w;
  var computed = _ember['default'].computed;

  var toArray = function toArray(value) {
    if (typeof value === 'string') {
      value = w(value);
    }
    return _ember['default'].A(value);
  };

  exports['default'] = function (defaultValue) {
    defaultValue = defaultValue || [];
    return computed({
      get: function get() {
        return toArray(defaultValue);
      },
      set: function set(key, value) {
        return toArray(value);
      }
    });
  };
});