define('ember-cli-mirage/shorthands/put', ['exports', 'ember-cli-mirage/utils/inflector', 'ember-cli-mirage/shorthands/utils'], function (exports, _emberCliMirageUtilsInflector, _emberCliMirageShorthandsUtils) {
  'use strict';

  /*
    Shorthands for PUT requests.
  */
  exports['default'] = {

    /*
      Update an object from the db, specifying the type.
         this.stub('put', '/contacts/:id', 'user');
    */
    string: function string(type, db, request) {
      var id = _emberCliMirageShorthandsUtils['default'].getIdForRequest(request);
      var putData = _emberCliMirageShorthandsUtils['default'].getJsonBodyForRequest(request);
      var attrs = putData[type];
      var collection = (0, _emberCliMirageUtilsInflector.pluralize)(type);
      attrs.id = id;

      if (!db[collection]) {
        console.error("Mirage: The route handler for " + request.url + " is trying to modify data from the " + collection + " collection, but that collection doesn't exist. To create it, create an empty fixture file or factory.");
      }

      var data = db[collection].update(id, attrs);

      var response = {};
      response[type] = data;

      return response;
    },

    /*
      Update an object from the db based on singular version
      of the last portion of the url.
         this.stub('put', '/contacts/:id');
    */
    undefined: function undefined(undef, db, request) {
      var id = _emberCliMirageShorthandsUtils['default'].getIdForRequest(request);
      var url = _emberCliMirageShorthandsUtils['default'].getUrlForRequest(request);
      var type = _emberCliMirageShorthandsUtils['default'].getTypeFromUrl(url, id);
      var collection = (0, _emberCliMirageUtilsInflector.pluralize)(type);
      var putData = _emberCliMirageShorthandsUtils['default'].getJsonBodyForRequest(request);
      var attrs = putData[type];

      if (!db[collection]) {
        console.error("Mirage: The route handler for " + request.url + " is trying to modify data from the " + collection + " collection, but that collection doesn't exist. To create it, create an empty fixture file or factory.");
      }

      var data = db[collection].update(id, attrs);

      var response = {};
      response[type] = data;

      return response;
    }

  };
});