define("ember-cli-mirage/orm/collection", ["exports"], function (exports) {
  /*
    An array of models, returned from one of the schema query
    methods (all, find, where). Knows how to update and destroy its models.
  */
  "use strict";

  var Collection = function Collection() {
    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    if (_.isArray(args[0])) {
      args = args[0];
    }
    this.push.apply(this, args);

    this.update = function (key, val) {
      this.forEach(function (model) {
        model.update(key, val);
      });
    };

    this.destroy = function () {
      this.forEach(function (model) {
        model.destroy();
      });
    };

    this.save = function () {
      this.forEach(function (model) {
        model.save();
      });
    };

    this.reload = function () {
      this.forEach(function (model) {
        model.reload();
      });
    };

    this.mergeCollection = function (collection) {
      var _this = this;

      collection.forEach(function (model) {
        _this.push(model);
      });

      return this;
    };

    return this;
  };

  Collection.prototype = Object.create(Array.prototype);

  exports["default"] = Collection;
});