define('ember-cp-validations/validators/format', ['exports', 'ember', 'ember-cp-validations/validators/base'], function (exports, _ember, _emberCpValidationsValidatorsBase) {
  /**
   * Copyright 2016, Yahoo! Inc.
   * Copyrights licensed under the New BSD License. See the accompanying LICENSE file for terms.
   */

  'use strict';

  var get = _ember['default'].get;
  var isNone = _ember['default'].isNone;
  var isEmpty = _ember['default'].isEmpty;

  /**
   *  Validate over a predefined or custom regular expression.
   *
   *   #### Options
   *  - `allowBlank` (**Boolean**): If true, skips validation if the value is empty
   *  - `type` (**String**): Can be the one of the following options [`email`, `phone`, `url`]
   *  - `regex` (**RegExp**): The regular expression to test against
   *
   *  ```javascript
   *  // Examples
   *  validator('format', {
   *    type: 'email'
   *  })
   *  validator('format', {
   *    allowBlank: true,
   *    type: 'phone'
   *  })
   *  validator('format', {
   *    type: 'url'
   *  })
   *  validator('format', {
   *      regex: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{4,8}$/,
   *      message: 'Password must include at least one upper case letter, one lower case letter, and a number'
   *  })
   *  ```
   *
   *  If you do not want to use the predefined regex for a specific type, you can do something like this
   *
   *  ```javascript
   *  // Example
   *  validator('format', {
   *    type: 'email',
   *    regex: /My Better Email Regexp/
   *  })
   *  ```
   *  This allows you to still keep the email error message but with your own custom regex.
   *  @class Format
   *  @module Validators
   *  @extends Base
   */
  exports['default'] = _emberCpValidationsValidatorsBase['default'].extend({
    regularExpressions: {
      email: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/,
      phone: /^([\+]?1\s*[-\/\.]?\s*)?(\((\d{3})\)|(\d{3}))\s*[-\/\.]?\s*(\d{3})\s*[-\/\.]?\s*(\d{4})\s*(([xX]|[eE][xX][tT]?[\.]?|extension)\s*([#*\d]+))*$/,
      url: /(?:([A-Za-z]+):)?(\/{0,3})[a-zA-Z0-9][a-zA-Z-0-9]*(\.[\w-]+)+([\w.,@?^=%&amp;:\/~+#-{}]*[\w@?^=%&amp;\/~+#-{}])??/
    },

    /**
     * Normalized options passed in by applying the desired regex or using the one declared
     *
     * @method buildOptions
     * @param  {Object}     options
     * @param  {Object}     defaultOptions
     * @param  {Object}     globalOptions
     * @return {Object}
     */
    buildOptions: function buildOptions() {
      var options = arguments.length <= 0 || arguments[0] === undefined ? {} : arguments[0];
      var defaultOptions = arguments.length <= 1 || arguments[1] === undefined ? {} : arguments[1];
      var globalOptions = arguments.length <= 2 || arguments[2] === undefined ? {} : arguments[2];

      var regularExpressions = get(this, 'regularExpressions');

      if (options.type && !isNone(regularExpressions[options.type]) && isNone(options.regex)) {
        options.regex = regularExpressions[options.type];
      }

      return this._super(options, defaultOptions, globalOptions);
    },

    validate: function validate(value, options) {
      if (isEmpty(Object.keys(options))) {
        return true;
      }

      if (options.allowBlank && isEmpty(value)) {
        return true;
      }

      if (options.regex && !options.regex.test(value)) {
        if (options.type) {
          return this.createErrorMessage(options.type, value, options);
        }
        return this.createErrorMessage('invalid', value, options);
      }

      return true;
    }
  });
});