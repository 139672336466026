// Should be dropped and usage fixed once http://git.io/vB5Pu is resolved.

define('ember-metal/get', ['ember'], function(Ember) {
  'use strict';

  return {
    'default': Ember['default'].get,
    'getProperties': Ember['default'].getProperties,
    'getWithDefault': Ember['default'].getWithDefault
  };
});
