define('ember-click-outside/mixins/click-outside', ['exports', 'ember', 'jquery'], function (exports, _ember, _jquery) {
  'use strict';

  var computed = _ember['default'].computed;
  var K = _ember['default'].K;

  var bound = function bound(fnName) {
    return computed(fnName, function () {
      return this.get(fnName).bind(this);
    });
  };

  exports['default'] = _ember['default'].Mixin.create({
    clickOutside: K,
    clickHandler: bound('outsideClickHandler'),

    outsideClickHandler: function outsideClickHandler(e) {
      var element = this.get('element');
      var $target = (0, _jquery['default'])(e.target);
      var isInside = $target.closest(element).length === 1;

      if (!isInside) {
        this.clickOutside(e);
      }
    },

    addClickOutsideListener: function addClickOutsideListener() {
      var clickHandler = this.get('clickHandler');
      (0, _jquery['default'])(window).on('click', clickHandler);
    },

    removeClickOutsideListener: function removeClickOutsideListener() {
      var clickHandler = this.get('clickHandler');
      (0, _jquery['default'])(window).off('click', clickHandler);
    }
  });
});