;(function() {
  'use strict';

  if (typeof window.Stripe === 'undefined') {
    // mocked for offline testing
    window.Stripe = {
      setPublishableKey: function() {},
      card: {
        createToken: function(_props, cb) {
          // it's easier to mock out the success then the fail --
          // this should probably be refactored to a service
          var response = { error: { message: 'fail :/' } };
          cb(200, response);
        },
        validateExpiry: function() {},
        validateCardNumber: function() {}
      }
    };
  }
}());
