define('ember-cp-validations/utils/utils', ['exports', 'ember', 'ember-string-ishtmlsafe-polyfill'], function (exports, _ember, _emberStringIshtmlsafePolyfill) {
  'use strict';

  exports.requireModule = requireModule;
  exports.unwrapString = unwrapString;

  /**
   * Copyright 2016, Yahoo! Inc.
   * Copyrights licensed under the New BSD License. See the accompanying LICENSE file for terms.
   */

  function requireModule(module) {
    var rjs = self.requirejs;

    if (rjs.has && rjs.has(module) || !rjs.has && (rjs.entries[module] || rjs.entries[module + '/index'])) {
      return self.require(module)['default'];
    }
  }

  function unwrapString(input) {
    if ((0, _emberStringIshtmlsafePolyfill['default'])(input)) {
      return input.toString();
    }

    return input;
  }
});