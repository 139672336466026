define('ember-plupload/system/file', ['exports', 'ember'], function (exports, _ember) {
  /* global mOxie, plupload */
  'use strict';

  var _get = _ember['default'].get;
  var alias = _ember['default'].computed.alias;
  var reads = _ember['default'].computed.reads;

  var RSVP = _ember['default'].RSVP;
  var mOxieFileReader = mOxie.FileReader;

  var keys = Object.keys;
  var computed = _ember['default'].computed;

  var mergeDefaults = function mergeDefaults(defaults, options) {
    var unsetKeys = _ember['default'].A(keys(defaults)).removeObjects(keys(options));
    var settings = _ember['default'].copy(options, true);

    for (var i = 0, len = unsetKeys.length; i < len; i++) {
      var key = unsetKeys[i];
      settings[key] = defaults[key];
    }
    return settings;
  };

  var settingsToConfig = function settingsToConfig(settings) {
    var _mergeDefaults = mergeDefaults({
      method: 'POST',
      accepts: ['application/json', 'text/javascript'],
      contentType: _get(this, 'type'),
      headers: {},
      data: {},
      maxRetries: 0,
      chunkSize: 0,
      multipart: true,
      fileKey: 'file'
    }, settings);

    var url = _mergeDefaults.url;
    var method = _mergeDefaults.method;
    var accepts = _mergeDefaults.accepts;
    var contentType = _mergeDefaults.contentType;
    var headers = _mergeDefaults.headers;
    var data = _mergeDefaults.data;
    var maxRetries = _mergeDefaults.maxRetries;
    var chunkSize = _mergeDefaults.chunkSize;
    var multipart = _mergeDefaults.multipart;
    var fileKey = _mergeDefaults.fileKey;

    if (headers.Accept == null) {
      if (!_ember['default'].Array.detect(accepts)) {
        accepts = _ember['default'].A([accepts]).compact();
      }
      headers.Accept = accepts.join(',');
    }

    // Switch setting content type according to
    // the type of request we're sending.
    if (contentType) {
      if (multipart) {
        data['Content-Type'] = contentType;
      } else {
        headers['Content-Type'] = contentType;
      }
    }

    return {
      url: url,
      method: method,
      headers: headers,
      multipart: multipart,
      multipart_params: data,
      max_retries: maxRetries,
      chunk_size: chunkSize,
      file_data_name: fileKey
    };
  };

  /**
    A representation of a single file being uploaded
    by the `UploadQueue`.
  
    @namespace ember-plupload
    @class File
    @extends Ember.Object
   */
  exports['default'] = _ember['default'].Object.extend({

    /**
      The unique ID of the file.
       @property id
      @type String
     */
    id: reads('file.id'),

    /**
      The name of the file.
       @property name
      @type String
     */
    name: alias('file.name'),

    /**
      The size of the file in bytes
       @property size
      @type Number
     */
    size: reads('file.size'),

    /**
      The content type of the file
       @property type
      @type String
     */
    type: reads('file.type'),

    /**
      The current upload progress of the file,
      which is a number between 0 and 100.
       @property progress
      @type Number
     */
    progress: computed({
      get: function get() {
        return _get(this, 'file.percent');
      }
    }),

    /**
      Remove the file from the upload queue.
       @method destroy
     */
    destroy: function destroy() {
      if (this.isDestroyed) {
        return;
      }
      _get(this, 'uploader').removeFile(_get(this, 'file'));
      this.isDestroyed = true;
    },

    upload: function upload(url, settings) {
      var uploader = _get(this, 'uploader');
      this._deferred = RSVP.defer('File: \'' + _get(this, 'id') + '\' Upload file');

      if (settings == null) {
        if (typeof url === 'object') {
          settings = url;
        } else {
          settings = { url: url };
        }
      } else if (url) {
        settings.url = url;
      }

      this.settings = settingsToConfig.call(this, settings);

      // Handle future versions of plupload with
      // a parallel upload api provided on the file object
      if (this.file.upload) {
        this.file.upload(this.settings);
      } else {
        if (this.file.status === plupload.FAILED) {
          this.file.status = plupload.QUEUED;
        }
        uploader.start();
      }

      return this._deferred.promise;
    },

    read: function read() {
      var options = arguments.length <= 0 || arguments[0] === undefined ? { as: 'data-url' } : arguments[0];

      var file = _get(this, 'file').getSource();
      /*jshint -W055 */
      var reader = new mOxieFileReader();
      /*jshint +W055 */

      var _RSVP$defer = RSVP.defer();

      var promise = _RSVP$defer.promise;
      var resolve = _RSVP$defer.resolve;
      var reject = _RSVP$defer.reject;

      reader.onloadend = resolve;
      reader.onerror = reject;

      switch (options.as) {
        case 'array-buffer':
          reader.readAsArrayBuffer(file);
          break;
        case 'data-url':
          reader.readAsDataURL(file);
          break;
        case 'binary-string':
          reader.readAsBinaryString(file);
          break;
        case 'text':
          reader.readAsText(file);
          break;
      }

      return promise.then(function () {
        return reader.result;
      }, function () {
        return reader.error;
      });
    }
  });
});