define('ember-cli-geo/services/geolocation', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  exports['default'] = _ember['default'].Service.extend(_ember['default'].Evented, {

    currentLocation: null,

    getLocation: function getLocation(geoOptions) {
      var self = this;

      return new _ember['default'].RSVP.Promise(function (resolve, reject) {

        navigator.geolocation.getCurrentPosition(function (geoObject) {
          self.trigger('geolocationSuccess', geoObject, resolve);
        }, function (reason) {
          self.trigger('geolocationFail', reason, reject);
        }, geoOptions);
      });
    },

    trackLocation: function trackLocation(geoOptions) {
      var self = this;

      return new _ember['default'].RSVP.Promise(function (resolve, reject) {

        navigator.geolocation.watchPosition(function (geoObject) {
          self.trigger('geolocationSuccess', geoObject, resolve);
        }, function (reason) {
          self.trigger('geolocationFail', reason, reject);
        }, geoOptions);
      });
    },

    geolocationDidSucceed: _ember['default'].on('geolocationSuccess', function (geoObject, resolve) {
      this.set('currentLocation', [geoObject.coords.latitude, geoObject.coords.longitude]);
      resolve(geoObject);
    }),

    geolocationDidFail: _ember['default'].on('geolocationFail', function (reason, reject) {
      reject(reason);
    })

  });
});