define("ember-cli-mirage/response", ["exports"], function (exports) {
  "use strict";

  exports["default"] = function (code, headers, data) {
    this.toArray = function () {
      return [code, headers, data];
    };

    return this;
  };
});