define("ivy-videojs/templates/components/ivy-videojs", ["exports"], function (exports) {
  "use strict";

  exports["default"] = Ember.HTMLBars.template((function () {
    return {
      meta: {
        "revision": "Ember@1.13.13",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 39,
            "column": 0
          }
        },
        "moduleName": "modules/ivy-videojs/templates/components/ivy-videojs.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        dom.insertBoundary(fragment, 0);
        return morphs;
      },
      statements: [["inline", "ivy-videojs-player", [], ["abort", "abort", "canplay", "canplay", "canplaythrough", "canplaythrough", "controls", ["subexpr", "@mut", [["get", "controls", ["loc", [null, [5, 11], [5, 19]]]]], [], []], "durationchange", "durationchange", "emptied", "emptied", "ended", "ended", "error", "error", "fluid", ["subexpr", "@mut", [["get", "fluid", ["loc", [null, [10, 8], [10, 13]]]]], [], []], "language", ["subexpr", "@mut", [["get", "language", ["loc", [null, [11, 11], [11, 19]]]]], [], []], "loadeddata", "loadeddata", "loadedmetadata", "loadedmetadata", "loadstart", "loadstart", "loop", ["subexpr", "@mut", [["get", "loop", ["loc", [null, [15, 7], [15, 11]]]]], [], []], "muted", ["subexpr", "@mut", [["get", "muted", ["loc", [null, [16, 8], [16, 13]]]]], [], []], "pause", "pause", "play", "play", "playbackRate", ["subexpr", "@mut", [["get", "playbackRate", ["loc", [null, [19, 15], [19, 27]]]]], [], []], "playing", "playing", "poster", ["subexpr", "@mut", [["get", "poster", ["loc", [null, [21, 9], [21, 15]]]]], [], []], "progress", "progress", "ratechange", "ratechange", "ready", "ready", "resize", "resize", "seeked", "seeked", "seeking", "seeking", "setup", ["subexpr", "@mut", [["get", "setup", ["loc", [null, [28, 8], [28, 13]]]]], [], []], "src", ["subexpr", "@mut", [["get", "src", ["loc", [null, [29, 6], [29, 9]]]]], [], []], "stalled", "stalled", "suspend", "suspend", "timeupdate", "timeupdate", "useractive", "useractive", "userinactive", "userinactive", "volume", ["subexpr", "@mut", [["get", "volume", ["loc", [null, [35, 9], [35, 15]]]]], [], []], "volumechange", "volumechange", "waiting", "waiting"], ["loc", [null, [1, 0], [38, 2]]]]],
      locals: [],
      templates: []
    };
  })());
});