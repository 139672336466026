define('ember-click-outside/components/click-outside', ['exports', 'ember', 'ember-click-outside/mixins/click-outside', 'ember-click-outside/templates/components/click-outside', 'jquery'], function (exports, _ember, _emberClickOutsideMixinsClickOutside, _emberClickOutsideTemplatesComponentsClickOutside, _jquery) {
  'use strict';

  var Component = _ember['default'].Component;
  var on = _ember['default'].on;
  var next = _ember['default'].run.next;

  exports['default'] = Component.extend(_emberClickOutsideMixinsClickOutside['default'], {
    layout: _emberClickOutsideTemplatesComponentsClickOutside['default'],

    clickOutside: function clickOutside(e) {
      var exceptSelector = this.attrs['except-selector'];
      if (exceptSelector && (0, _jquery['default'])(e.target).closest(exceptSelector).length > 0) {
        return;
      }

      this.sendAction();
    },

    _attachClickOutsideHandler: on('didInsertElement', function () {
      next(this, this.addClickOutsideListener);
    }),

    _removeClickOutsideHandler: on('willDestroyElement', function () {
      this.removeClickOutsideListener();
    })
  });
});