define('ember-cli-flash/flash/object', ['exports', 'ember-cli-flash/utils/computed'], function (exports, _emberCliFlashUtilsComputed) {
  'use strict';

  exports['default'] = Ember.Object.extend(Ember.Evented, {
    exitTimer: null,
    exiting: false,
    isExitable: true,
    initializedTime: null,

    _guid: _emberCliFlashUtilsComputed['default'].guidFor('message').readOnly(),

    init: function init() {
      this._super.apply(this, arguments);

      if (Ember.get(this, 'sticky')) {
        return;
      }
      this.timerTask();
      this._setInitializedTime();
    },
    destroyMessage: function destroyMessage() {
      this._cancelTimer();
      var exitTaskInstance = Ember.get(this, 'exitTaskInstance');
      if (exitTaskInstance) {
        Ember.run.cancel(exitTaskInstance);
        this._teardown();
      } else {
        this.exitTimerTask();
      }
    },
    exitMessage: function exitMessage() {
      if (!Ember.get(this, 'isExitable')) {
        return;
      }
      this.exitTimerTask();
      this.trigger('didExitMessage');
    },
    willDestroy: function willDestroy() {
      var onDestroy = Ember.get(this, 'onDestroy');
      if (onDestroy) {
        onDestroy();
      }

      this._cancelTimer();
      this._cancelTimer('exitTaskInstance');
      this._super.apply(this, arguments);
    },
    preventExit: function preventExit() {
      Ember.set(this, 'isExitable', false);
    },
    allowExit: function allowExit() {
      Ember.set(this, 'isExitable', true);
      this._checkIfShouldExit();
    },
    timerTask: function timerTask() {
      var _this = this;

      var timeout = Ember.get(this, 'timeout');
      if (!timeout) {
        return;
      }
      var timerTaskInstance = Ember.run.later(function () {
        _this.exitMessage();
      }, timeout);
      Ember.set(this, 'timerTaskInstance', timerTaskInstance);
    },
    exitTimerTask: function exitTimerTask() {
      var _this2 = this;

      if (Ember.get(this, 'isDestroyed')) {
        return;
      }
      Ember.set(this, 'exiting', true);
      var extendedTimeout = Ember.get(this, 'extendedTimeout');
      if (extendedTimeout) {
        var exitTaskInstance = Ember.run.later(function () {
          _this2._teardown();
        }, extendedTimeout);
        Ember.set(this, 'exitTaskInstance', exitTaskInstance);
      } else {
        this._teardown();
      }
    },

    // private
    _setInitializedTime: function _setInitializedTime() {
      var currentTime = new Date().getTime();

      Ember.set(this, 'initializedTime', currentTime);
    },
    _getElapsedTime: function _getElapsedTime() {
      var currentTime = new Date().getTime();
      var initializedTime = Ember.get(this, 'initializedTime');

      return currentTime - initializedTime;
    },
    _cancelTimer: function _cancelTimer() {
      var taskName = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'timerTaskInstance';

      var taskInstance = Ember.get(this, taskName);
      if (taskInstance) {
        Ember.run.cancel(taskInstance);
      }
    },
    _checkIfShouldExit: function _checkIfShouldExit() {
      if (this._getElapsedTime() >= Ember.get(this, 'timeout') && !Ember.get(this, 'sticky')) {
        this._cancelTimer();
        this.exitMessage();
      }
    },
    _teardown: function _teardown() {
      var queue = Ember.get(this, 'flashService.queue');
      if (queue) {
        queue.removeObject(this);
      }
      this.destroy();
      this.trigger('didDestroyMessage');
    }
  });
});