define('ember-cli-mirage/index', ['exports', 'ember-cli-mirage/factory', 'ember-cli-mirage/response', 'ember-cli-mirage/faker', 'ember-cli-mirage/orm/model', 'ember-cli-mirage/orm/associations/has-many', 'ember-cli-mirage/orm/associations/belongs-to'], function (exports, _emberCliMirageFactory, _emberCliMirageResponse, _emberCliMirageFaker, _emberCliMirageOrmModel, _emberCliMirageOrmAssociationsHasMany, _emberCliMirageOrmAssociationsBelongsTo) {
  'use strict';

  exports.faker = _emberCliMirageFaker['default'];
  exports.Model = _emberCliMirageOrmModel['default'];
  exports.hasMany = _emberCliMirageOrmAssociationsHasMany['default'];
  exports.belongsTo = _emberCliMirageOrmAssociationsBelongsTo['default'];
  exports['default'] = {
    Factory: _emberCliMirageFactory['default'],
    Response: _emberCliMirageResponse['default'],
    hasMany: function hasMany(type) {
      return new _emberCliMirageOrmAssociationsHasMany['default'](type);
    },
    belongsTo: function belongsTo(type) {
      return new _emberCliMirageOrmAssociationsBelongsTo['default'](type);
    }
  };
});