define('ember-moment/computeds/format', ['exports', 'ember', 'moment', 'ember-getowner-polyfill', 'ember-moment/computeds/-base'], function (exports, _ember, _moment, _emberGetownerPolyfill, _emberMomentComputedsBase) {
  'use strict';

  var CONFIG_KEY = 'config:environment';

  var get = _ember['default'].get;
  var assert = _ember['default'].assert;

  exports['default'] = (0, _emberMomentComputedsBase['default'])(function formatComputed(params) {
    assert('At least one datetime argument required for moment computed', params.length);

    var owner = (0, _emberGetownerPolyfill['default'])(this);
    var momentArgs = [params[0]];

    var maybeOutputFormat = params[1];

    if (params.length > 2) {
      momentArgs.push(params[2]);
    } else if (owner && owner.hasRegistration && owner.hasRegistration(CONFIG_KEY)) {
      var config = owner.resolveRegistration(CONFIG_KEY);

      if (config) {
        maybeOutputFormat = get(config, 'moment.outputFormat');
      }
    }

    return _moment['default'].apply(this, momentArgs).format(maybeOutputFormat);
  });
});