define('ember-plupload/system/make-file-filter', ['exports', 'ember'], function (exports, _ember) {
  /* global plupload */
  'use strict';

  var RSVP = _ember['default'].RSVP;

  exports['default'] = function (name, filterBody) {
    plupload.addFileFilter(name, function (configValue, file, callback) {
      var deferred = RSVP.defer();
      var self = this;

      filterBody(configValue, file, deferred.resolve, deferred.reject);
      deferred.promise.then(function () {
        callback(true);
      }, function (code, message) {
        self.trigger('Error', {
          code: code,
          message: message,
          file: file
        });
        callback(false);
      });
    });
  };
});