define('ember-cli-mirage/shorthands/post', ['exports', 'ember-cli-mirage/utils/inflector', 'ember-cli-mirage/shorthands/utils'], function (exports, _emberCliMirageUtilsInflector, _emberCliMirageShorthandsUtils) {
  'use strict';

  /*
    Shorthands for POST requests.
  */
  exports['default'] = {

    /*
      Push a new model of type *type* to the db.
       For example, this will push a 'user':
        this.stub('post', '/contacts', 'contact');
    */
    string: function string(_string, db, request) {
      var type = _string;
      var collection = (0, _emberCliMirageUtilsInflector.pluralize)(_string);
      var postData = _emberCliMirageShorthandsUtils['default'].getJsonBodyForRequest(request);
      var attrs = postData[type];

      if (!db[collection]) {
        console.error("Mirage: The route handler for " + request.url + " is trying to insert data into the " + collection + " collection, but that collection doesn't exist. To create it, create an empty fixture file or factory.");
      }

      var model = db[collection].insert(attrs);

      var response = {};
      response[type] = model;

      return response;
    },

    /*
      Push a new model to the db. The type is found
      by singularizing the last portion of the URL.
       For example, this will push a 'contact'.
        this.stub('post', '/contacts');
    */
    undefined: function undefined(undef, db, request) {
      var url = _emberCliMirageShorthandsUtils['default'].getUrlForRequest(request);
      var type = _emberCliMirageShorthandsUtils['default'].getTypeFromUrl(url);
      var collection = (0, _emberCliMirageUtilsInflector.pluralize)(type);
      var postData = _emberCliMirageShorthandsUtils['default'].getJsonBodyForRequest(request);
      var attrs = postData[type];

      if (!db[collection]) {
        console.error("Mirage: The route handler for " + request.url + " is trying to insert data into the " + collection + " collection, but that collection doesn't exist. To create it, create an empty fixture file or factory.");
      }

      var model = db[collection].insert(attrs);

      var response = {};
      response[type] = model;
      return response;
    }

  };
});